.banner-area {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* margin-top: 10px; */
  padding: 50px;
  background-image: linear-gradient(
      rgba(190, 193, 211, 0.9),
      rgba(73, 180, 164, 0.9)
    ),
    url(joblist.png);
  height: 95vh;
  background-size: cover;
  color: rgb(239, 234, 234);
  text-align: left;
  z-index: 1;
}

.banner-area .overlay-bg {
  opacity: 0.95;
}

/* .search-div {
  /* width: 80em; 
  /* margin-top: 70px; 
} */

.banner-content {
  display: flex;
  flex-direction: column;
  gap: 2px;
  text-align: center;
}

.text {
  text-align: left;
  /* top: 50px; */
}

.banner-content h1 {
  font-size: 48px;
  font-weight: 300;
  line-height: 1em;
  /* margin-top: 20px; */
}

.banner-content h1 span {
  color: #49e4fa;
}

@media (max-width: 991.98px) {
  .banner-content h1 {
    font-size: 36px;
  }
}

@media (max-width: 991.98px) {
  .banner-content h1 br {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .banner-content h1 {
    font-size: 45px;
  }
}

@media (max-width: 414px) {
  .banner-content h1 {
    font-size: 32px;
  }
}

.banner-content p span {
  color: #49e4fa;
}

@media (max-width: 1280px) {
  .banner-content br {
    display: none;
  }
}

.banner-content .form-wrap {
  background-color: #70acfc;
  border: 1px solid #b8aaf3;
  padding: 5px;
}

.banner-content .form-wrap .nice-select {
  width: 100%;
}

.banner-content .form-wrap .form-control {
  height: 10px;
  border-radius: 0;
  font-size: 14px;
}

.form-wrap {
  width: 850px;
}

.banner-content .form-wrap .btn-info {
  background-color: #49e4fa;
  width: 100%;
  height: auto;
  color: #fff;
  border-radius: 0;
  border: none;
  /* text-transform: ; */
}

.banner-content .form-wrap {
  font-size: 14px;
  margin: 20px;
}

.btn {
  font-size: 14px;
}

@media (max-width: 960px) {
  .banner-content .form-cols {
    margin-bottom: 30px;
  }

  .banner-content .form-cols:last-child {
    margin-bottom: 0;
  }
}

.default-select {
  height: 40px;
}

.default-select .nice-select {
  border: none;
  border-radius: 0px;
  height: 40px;
  background: #f9f9ff;
  padding-left: 20px;
  padding-right: 40px;
}

.default-select .nice-select .list {
  margin-top: 0;
  border: none;
  border-radius: 0px;
  box-shadow: none;
  width: 100%;
  padding: 10px 0 10px 0px;
}

.default-select .nice-select .list .option {
  font-weight: 300;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  line-height: 28px;
  min-height: 28px;
  font-size: 12px;
  padding-left: 20px;
}

.default-select .nice-select .list .option.selected {
  color: #49e4fa;
  background: transparent;
}

.default-select .nice-select .list .option:hover {
  color: #49e4fa;
  background: transparent;
}

.default-select .current {
  margin-right: 50px;
  font-weight: 300;
}

.default-select .nice-select::after {
  right: 20px;
}

.card-box {
  width: 450px;
  height: auto;
  border: 2px solid #f9f9ff;
  margin: 40px 50px;
  padding: 20px;
  background-color: #f9f9ff;
}

.btns a {
  color: black;
  float: right;
  font-size: small;
  border: 2px solid #f9f9ff;
  padding: 10px 20px;
  background-color: white;
}

.category {
  width: 450px;
  height: auto;
  border: 2px solid #f9f9ff;
  margin: 40px 50px;
  padding: 20px;
  background-color: #f9f9ff;
}

.features-area {
  margin-top: -55px;
  padding: 0px 20px 20px;
  /* background-color: #f9f9ff; */
}

.single-feature {
  text-align: center;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 0px 40px 0px rgba(132, 144, 255, 0.2);
  padding: 25px;
}

.single-feature:hover {
  box-shadow: -14.142px 14.142px 20px 0px rgba(157, 157, 157, 0.5);
  cursor: pointer;
}

.single-feature p {
  /* margin-bottom: 0; */
  text-align: center;
}

/* .single-feature h4 {
  /* padding-bottom: 15px; 
}*/

@media (max-width: 960px) {
  .single-feature {
    margin-bottom: 30px;
  }
}

.ftco-section {
  padding: 6em 0;
  position: relative;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.heading-section .subheading {
  font-size: 12px;
  display: block;
  margin-bottom: 0px;
  color: #206dfb;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
}

form {
  margin-top: 0;
}

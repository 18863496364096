@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");

* {
  padding: 0;
  margin: 0;
}
.profile {
  width: 100%;
  background: rgb(255, 255, 255);
  /* background: radial-gradient(
    circle,
    rgba(245, 243, 243, 1) 100%,
    rgba(255, 255, 255, 1) 100%
  ); */
  height: 100vh;
  overflow-y: hidden;
  position: fixed;
  top: 10%;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 85vh;
  margin-top: 20px;
}
.container .card {
  height: 100%;
  width: 70%;
  background-color: #fff;
  position: relative;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  font-family: "Poppins", sans-serif;
  border-radius: 20px;
}
.container .card .form {
  width: 100%;
  height: 100%;

  display: flex;
}
.container .card .left-side {
  width: 30%;
  background-color: rgb(3, 3, 61);
  height: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 20px 30px;
  box-sizing: border-box;
}
/*left-side-start*/
.left-heading {
  color: #fff;
}
.steps-content {
  margin-top: 10px;
  color: #fff;
}
.steps-content p {
  font-size: 12px;
  margin-top: 10px;
}
.progress-bar {
  list-style: none;
  /*color:#fff;*/
  margin-top: 10px;
  font-size: 13px;
  font-weight: 700;
  counter-reset: container 0;
}
.progress-bar li {
  position: relative;
  margin-left: 40px;
  margin-top: 45px;
  counter-increment: container 1;
  color: #4f6581;
}
.progress-bar li::before {
  content: counter(container);
  line-height: 25px;
  text-align: center;
  position: absolute;
  height: 25px;
  width: 25px;
  border: 1px solid #4f6581;
  border-radius: 50%;
  left: -40px;
  top: -5px;
  z-index: 10;
  background-color: #304767;
}

.progress-bar li::after {
  content: "";
  position: absolute;
  height: 90px;
  width: 2px;
  background-color: #4f6581;
  z-index: 1;
  left: -27px;
  top: -70px;
}

.progress-bar li.active::after {
  background-color: #fff;
}

.progress-bar li:first-child:after {
  display: none;
}

/*.progress-bar li:last-child:after{*/
/*  display:none;  */
/*}*/
.progress-bar li.active::before {
  color: #fff;
  border: 1px solid #fff;
}
.progress-bar li.active {
  color: #fff;
}
.d-none {
  display: none;
}

h2 {
  color: #000;
  font-family: "Poppins", sans-serif;
}

h4 {
  margin-bottom: 1em;
}

/*left-side-end*/
.container .card .right-side {
  width: 65%;
  background-color: #fff;
  height: 100%;
  border-radius: 20px;
}
/*right-side-start*/
.main {
  display: none;
}
.active {
  display: block;
}
.main {
  padding: 40px;
}
.main small {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  height: 30px;
  width: 30px;
  background-color: #fff;
  border-radius: 50%;
  color: #4caf50;
  font-size: 19px;
}
.text {
  margin-top: 10px;
}
.congrats {
  text-align: center;
}
.text p {
  margin-top: 10px;
  font-size: 13px;
  font-weight: 700;
  color: #cbced4;
}
.input-text {
  margin: 22px 0;
  display: flex;
  gap: 20px;
}

.input-text .input-div {
  width: 100%;
  position: relative;
}

input[type="text"] {
  width: 100%;
  height: 40px;
  border: none;
  outline: 0;
  border-radius: 5px;
  border: 1px solid #cbced4;
  gap: 20px;
  box-sizing: border-box;
  padding: 0px 10px;
  word-wrap: break-word;
}
input[type="date"] {
  width: 100%;
  height: 40px;
  border: none;
  outline: 0;
  border-radius: 5px;
  border: 1px solid #cbced4;
  gap: 20px;
  box-sizing: border-box;
  padding: 0px 10px;
  word-wrap: break-word;
}

select {
  width: 100%;
  height: 40px;
  border: none;
  outline: 0;
  border-radius: 5px;
  border: 1px solid #cbced4;
  gap: 20px;
  box-sizing: border-box;
  padding: 0px 10px;
}
.input-text .input-div span {
  position: absolute;
  /* top: 10px;
  left: 10px;  */
  color: #000;
  transition: all 0.5s;
}
/* label {
  position: absolute;
  left: -7px;
  color: #000;
  transition: all 0.5s;
  top: -30px;
  font-size: 10px;
  font-weight: 600;
} */
.calender {
  padding-top: 4px;
  font-size: 13px;
}
.input-div input:focus ~ span,
.input-div input:valid ~ span {
  top: -15px;
  left: 6px;
  font-size: 13px;
  font-weight: 600;
}

.input-div span {
  top: -20px;
  left: 6px;
  font-size: 10px;
}
.buttons button {
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: #0ea5e9;
  font-size: 15px;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
}

.button_space {
  display: inline-flex;
  gap: 50px;
}
.button_space button:nth-child(1) {
  background-color: #cbced4;
  color: #000;
  border: 1px solid#000;
}
.user_card {
  margin-top: 20px;
  margin-bottom: 40px;
  height: 200px;
  width: 100%;
  border: 1px solid #c7d3d9;
  border-radius: 10px;
  display: flex;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
}
.user_card span {
  height: 80px;
  width: 100%;
  background-color: #dfeeff;
}
.circle {
  position: absolute;
  top: 40px;
  left: 60px;
}
.circle span {
  height: 70px;
  width: 70px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fff;
  border-radius: 50%;
}
.circle span img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.social {
  display: flex;
  position: absolute;
  top: 100px;
  right: 10px;
}
.social span {
  height: 30px;
  width: 30px;
  border-radius: 7px;
  background-color: #fff;
  border: 1px solid #cbd6dc;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  color: #cbd6dc;
}
.social span i {
  cursor: pointer;
}
.heart {
  color: red !important;
}
.share {
  color: red !important;
}
.user_name {
  position: absolute;
  top: 110px;
  margin: 10px;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.user_name h4 {
  color: #4c5b68;
}
.detail {
  /*margin-top:10px;*/
  display: flex;
  justify-content: space-between;
  margin-right: 50px;
}
.detail p {
  font-size: 12px;
  font-weight: 700;
}
.detail p a {
  text-decoration: none;
  color: blue;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}

.warning {
  border: 1px solid red !important;
}

/*right-side-end*/
@media (max-width: 750px) {
  .container {
    height: scroll;
  }
  .container .card {
    max-width: 350px;
    height: auto !important;
    margin: 30px 0;
  }
  .container .card .right-side {
    width: 100%;
  }
  .input-text {
    display: block;
  }

  .input-text .input-div {
    margin-top: 20px;
  }

  .container .card .left-side {
    display: none;
  }
}

.skills-container {
  display: flex;
  flex-wrap: wrap;
}

.skill {
  display: inline-block;
  background-color: #e0e0e0;
  color: #333;
  font-size: 14px;
  padding: 4px 8px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.remove-skill {
  margin-left: 4px;
  background-color: transparent;
  color: #333;
  border: none;
  cursor: pointer;
}

.add-skill {
  display: flex;
  align-items: center;
  width: 420px;
}

.add-skill input[type="text"] {
  flex-grow: 1;
  padding: 8px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.add-skill button {
  margin-left: 10px;
  padding: 8px 12px;
  font-size: 14px;
  background-color: #ffffff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 150px;
}

.suggested-skills {
  margin-top: 10px;
}

.suggested-skill {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 6px 12px;
  font-size: 14px;
  background-color: #f0f0f0;
  color: #333;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.right-side {
  overflow: hidden;
}

.main {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
